let conf = config;
import _object from "lodash/object";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";

export default {
  state: () => ({
    config: conf,
  }),
  mutations: {
    replace(state, payload) {
      if (typeof payload !== "object" || payload === null) {
        debug("can not replace config, must be object payload", 2, payload);
        return false;
      }

      state.config = payload;
      return true;
    },
    merge(state, payload) {
      if (typeof payload !== "object" || payload === null) {
        debug("can not merge config, must be object payload", 2, payload);
        return false;
      }

      state.config = _object.merge(state.config, payload);
      return true;
    },
    set(state, payload) {
      let path = false,
        value;

      // validate payload as object
      if (typeof payload !== "object" || payload == null) {
        debug("Can not set config value, payload must be an object", 2, payload);
        return false;
      }

      // validate value property
      if (typeof payload.value === "undefined") {
        debug('Can not set config value, payload has no "value" property', 2, payload);
        return false;
      }

      value = payload.value;

      // find path in the "path" or "key" of the payload.
      if (payload.hasOwnProperty("path")) {
        path = payload.path;
      }

      if (payload.hasOwnProperty("key")) {
        path = payload.key;
      }

      if (typeof path !== "string") {
        debug(
          'Can not set config value, payload must have a "key" or "path" of type string',
          2,
          payload
        );
        return false;
      }

      _object.set(state.config, path, value);

      return true;
    },
  },
  actions: {
    updateFromServer({ commit }, options) {
      if (!options) {
        options = {};
      }

      let allowLoadFromWindow = options.allowLoadFromWindow || false;

      if (
        allowLoadFromWindow &&
        config.useSSR &&
        !utilities.isSSR() &&
        window &&
        window.__SAFFRON_CONFIG__
      ) {
        commit("merge", window.__SAFFRON_CONFIG__);
        return new Promise((resolve) => {
          resolve(window.__SAFFRON_CONFIG__);
        });
      }

      return new Promise(async (resolve, reject) => {
        let result = await asyncOperations({}, this).asyncCall("config", {});

        if (result.isError) {
          reject(result);
        }

        commit("merge", result.data);
        resolve(result.data);
      });
    },
  },
  getters: {
    all(state) {
      return state.config;
    },
    get: (state, getters) => (path, defaultValue) => {
      return getters["value"](path, defaultValue);
    },
    value: (state) => (path, defaultValue) => {
      return _object.get(state.config, path, defaultValue);
    },
  },
};
